
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function ComplaintsPage({ location }) {
  return (
    <Layout location={location}>
      <Seo title="Complaints procedure" />
      <section className="mt-8 lg:mt-18 mb-20">
        <header>
          <h1>Complaints procedure</h1>
        </header>
        <article className="prose lg:prose-xl pt-16 pb-64">
          <p>Our initial conversation attempts to discover how we can best understand how we can serve your interests and needs and we want to ensure that you fully enjoy and value the courses.</p>
          <p>If you have any complaints about any aspects of the event or its administration, this should in the first instance be directed to us, at hello@extremeautomation.io and we will of course do our best to resolve any complaint.</p>
          <p>Your complaint will be acknowledged by email within one week. If your complaint requires longer consideration to offer a fuller explanation to you, we will kee you informed for any reason for delay and a clear indication of the date when we expect to reach a conclusion.</p>
          <p>If you are not satisfied that your complaint has been resolved to your satisfaction we ask you to contact an independent company - Nativa SIA (registration number LV40003886864). They are an independent company who will consider your complaint and whose judgement and verdict will be binding upon our company, without infringing your rights to seek any other form of redress in any way that you wish to pursue. We would enact the binding judgement of the independent person promptly, including taking measures to change any procedures processes or behaviors that gave rise to the complaint.</p>
          <p>Any complaint is treated with the utmost discretion and is confidential.</p>
          <p>Any complaint will be recorded in a client file and kept for five years.</p>
          <p>For these events offered to our corporate clients, the complaints procedure is the same and is an addition to contractual agreements between the client and the company.</p>
          <p>The corporate client company may have their own enrolment process, however each participant will have access to the named course director from Nativa SIA for a pre-course conversation.</p>
        </article>
      </section>
    </Layout>
  )
}

export default ComplaintsPage
